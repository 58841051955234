import React from "react";

var GoBack = function(props){
    return (
        <button
            className="go-back pull-right"
            onClick={ ()=> props.history.goBack()}>
            {/*{strings[props.lang].web["go-back"]}*/}
            <img src={require("../assets/img/back.svg").default} alt="arrow back"/>
        </button>
    )
};

export default GoBack;