import React from 'react';
import data from '../assets/text-data.json';
import strings from '../assets/strings.json';
import {withRouter} from 'react-router-dom'
import GoBack from "./BackButton";
import RisksList from "./RisksList";
import axios from "axios";

class IntoleranceItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }

    onLoadData() {
        axios.get(this.props.hash + "/plan/", {
            params: {
                lang: this.props.lang
            }
        })
            .then(result => {
                this.setState({
                    data: result.data.userData.userData[this.props.category][this.props.subcategory]
                });
            })
            .catch(err => {
                console.log(err);
            });
    }


    componentDidMount() {
        this.onLoadData();
    }


    render() {
        const lang = this.props.lang;
        const category = this.props.category;
        const subcategory = this.props.subcategory;
        let subcategoryIcon = "../assets/img/" + subcategory + ".png";
        let subcategoryLabel = data[lang].subcategories[category][subcategory];
        let observationLabel = strings[lang].web["observations"];
        let intoleranceSummary = null;

        let foodlist = data[lang].foodLists.map(function (e, i) {
            if (e.label === subcategoryLabel) {
                return data[lang].foodLists[i].foodList.map(function (e, i) {
                    let bullets = e.bullets.map(function (e) {
                        return <li key={e}>{e}</li>;
                    });
                    return (
                        <div key={i}>
                            <ul key={i}>{bullets}</ul>
                        </div>
                    )
                });
            }
            return '';
        });

        if (this.state.data) {
            intoleranceSummary = this.state.data.summary;
        }
        return (
            <>
                <GoBack history={this.props.history} lang={lang}/>
                <div className="intolerance-container">
                    <img src={require("../assets/img/" + subcategory + ".png").default} alt="" />
                    <div className="intolerance-title">
                        <h5><b>{subcategoryLabel}</b></h5>
                    </div>
                    <div className="intolerance-summary">
                        <div className="text-container">
                            <span>{intoleranceSummary}</span>
                        </div>
                    </div>
                </div>
                <div className="intolerance-scale-container">
                    <div className="horizontal-bar-percentage">
                        <div className="percentage-container">
                            <div className="column-group">
                            </div>
                        </div>
                    </div>
                </div>

                <RisksList
                    lang={lang}
                    hash={this.props.match.params.hash}
                    category={category}
                    subcategory={subcategory}/>


                <h5>{observationLabel}</h5>

                {foodlist}

            </>

        );
    }
}

export default withRouter(IntoleranceItem);
