import React from 'react';
import HorizontalBar from './HorizontalBar';
import config from '../config/config.json';
import strings from '../assets/strings.json';
import textData from '../assets/text-data.json';
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import SomethingWentWrong from "./SomethingWentWrong";

class BarList extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			data: null,
			loading: false,
            hasError: false,
            error: null
		}
	}

	onLoadData() {
        this.setState({loading: true});
        axios.get(this.props.hash + "/plan/", {
            params: {
                lang: this.props.lang
            }
        })
            .then(result => {
                let data = {};
                this.setState({loading: false});
                config.categories.forEach(function(category){
                    data[category] = {};
                    config.subcategories[category].forEach(function(subcategory){
                        data[category][subcategory] = result.data.userData.userData[category][subcategory];
                    });
                });
                this.setState({
                    data: data
                });
            })
            .catch(err => {
                // console.log(err);
                this.setState({
                    loading: false,
                    hasError: true,
                    error: err
                });
            });
    }

	componentDidMount(){
        this.onLoadData();
	}

	render(){
		let component = this;
		let horizontalBars = <Loading/>;
        let category = this.props.category;
        let lang = this.props.lang;
        let clickString = strings[lang].web["click-for-details"];
        let subTitle = strings[lang].web[category+"-sub-title"];
        let groups = config["subcategory-groups"][category];

        if(!this.state.loading && this.state.data !== null){

            let data = this.state.data;
            horizontalBars = Object.keys(groups).map(function(groupKey, i){
                if(groupKey === "intolerance"){
                    return (
                        <div key={i} className="area" style={{ margin: "40px 0" }}>
                            {groups[groupKey].map(function(subcategory, j){
                                let subcategoryLabel = textData[lang].subcategories[category][subcategory];
                                return (
                                    <Link to={ {
                                        pathname: process.env.REACT_APP_FRONTEND_URL_PREFIX + [component.props.hash, component.props.category, subcategory].join("/"),
                                        search: '?value='+(data[category][subcategory].value || data[category][subcategory].risk_score)
                                    } } key={j}>
                                        <div className="intolerance-container clickable">
                                            <img src={require("../assets/img/" + subcategory + ".png").default} alt=""/>
                                            <div className="intolerance-title">
                                                <h5><b>{subcategoryLabel}</b></h5>
                                            </div>
                                            <div className="intolerance-summary">
                                                <div className="text-container">
                                                    <span>{data[category][subcategory].summary.join('')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                            <div className="intolerance-scale-container"/>
                        </div>
                    )
                }
                if( groupKey !== "intolerance" ){
                    return(
                        <div key={i} className="area">
                            <h4 className="subtitle">
                                {strings[lang].web[category][groupKey]}
                            </h4>
                            {groups[groupKey].map(function(subcategory, j){
                                let subcategoryLabel = textData[lang].subcategories[category][subcategory];
                                return (
                                    <Link to={{
                                        pathname: process.env.REACT_APP_FRONTEND_URL_PREFIX + [component.props.hash, component.props.category, subcategory].join("/"),
                                        search: '?value='+(data[category][subcategory].value || data[category][subcategory].risk_score)
                                    }} key={j}>
                                        <div className="horizontal-bar-container clickable">
                                            <img src={require("../assets/img/" + subcategory + ".png").default} alt="" />
                                            <h5>{subcategoryLabel}</h5>
                                            <div className="horizontal-bar">
                                                <HorizontalBar
                                                    activeColor={config.colors[category + "-color"]}
                                                    inactiveColor={config.colors[category + "-color-faded"]}
                                                    value={data[category][subcategory].value || data[category][subcategory].risk_score}
                                                    stroke="black"
                                                    strokeWidth={0}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                            <div className="scale-container">
                                <div className="horizontal-bar-percentage">
                                    <div className="percentage-container">
                                        <div className="column-group">
                                            <div className="all-25">
                                                <div className="percentage text-left">
                                                    <small>0%</small>
                                                </div>
                                            </div>
                                            <div className="all-15">
                                                <div className="percentage text-left">
                                                    <small>25%</small>
                                                </div>
                                            </div>
                                            <div className="all-20">
                                                <div className="percentage text-center">
                                                    <small>50%</small>
                                                </div>
                                            </div>
                                            <div className="all-15">
                                                <div className="percentage text-right">
                                                    <small>75%</small>
                                                </div>
                                            </div>
                                            <div className="all-25">
                                                <div className="percentage text-right">
                                                    <small>100%</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
                return '';
            });
        }
        if( !this.state.hasError) {
            return (
                <>
                    <h3>{subTitle}</h3>
                    <small>{clickString}</small>
                    {horizontalBars}
                </>
            );
        }else{
            return <SomethingWentWrong error={ this.state.error } lang={lang}/>
        }

	}
}
export default BarList;
