import React from 'react';
import termsData from '../assets/terms.json';
import GoBack from "../components/BackButton";

var Terms = function(props){
	var lang = props.lang;
	if(lang == null){
		return <div/>;
	}

	return (
		<>
			<GoBack history={ props.history } />
			<div dangerouslySetInnerHTML={{__html: termsData[props.lang]}}/>
		</>
	)
};

export default Terms;