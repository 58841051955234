import React from 'react';
import strings from '../assets/strings.json';

let somethingWentWrong = (props) => {

    // eslint-disable-next-line no-restricted-globals

    if(props.error.message === "Network Error") {
        console.log( "Provavelmente, o Backend caiu" );
    }
    return (
        <div className="error-container">
            <img src={require("../assets/img/404-adna.png").default} alt="DNA" />
            <h2>{strings[props.lang].web["error-title"]}</h2>
            <p>{strings[props.lang].web["error-message"]}</p>
            <a href={window.location.href} className="reload">Reload</a>
        </div>
    );
};


export default somethingWentWrong;