import React from 'react';
import Header from './Header';
import BarList from '../components/BarList';
import FoodList from '../components/FoodList';
import SubcategoryItem from '../components/SubcategoryItem';
import Terms from '../page/Terms';
import Help from '../page/Help';
import Cookies from '../page/Cookies';
import Refs from "../page/Refs";
import IntoleranceItem from "../components/IntoleranceItem";

var LandscapeApp = function(props){
	var getMainComponent = function(){
		var component;
		let category = props.match.params.category;
		let subcategory = props.match.params.subcategory;
		/* Subcategory page. May be a food list or a list of recommendations. */
		if(props.match.params.subcategory != null){

			if(category === "food" && !(subcategory === 'food_lactose' || subcategory === 'food_gluten')){
				return {
					component: <FoodList
								lang={props.lang}
								hash={props.match.params.hash}
								category={category}
								subcategory={subcategory}/>
				}
			}
			else if (category === "food" && (subcategory === 'food_lactose' || subcategory === 'food_gluten')  ){
				return {
					component: <IntoleranceItem
								lang={props.lang}
								hash={props.match.params.hash}
								category={category}
								subcategory={subcategory}
								history={props.history}/>
				}
			}
			else{
				return {
					component: <SubcategoryItem
								lang={props.lang}
								hash={props.match.params.hash}
								category={category}
								subcategory={subcategory}
								history={props.history}/>
				}
			}
		}
		/* Category page. */
		if(category != null){
			component = <BarList lang={props.lang} hash={props.match.params.hash}
							category={category}/>;
		}
		/* Terms&Conditions page. */
		if(props.match.params.terms != null){
			component = <Terms lang={props.lang} history={props.history}/>;
		}
		/* Help page. */
		if(props.match.params.help != null){
			component = <Help lang={props.lang} history={props.history}/>;
		}
		/* Cookies page. */
		if(props.match.params.cookies != null){
			component = <Cookies lang={props.lang} history={props.history}/>;
		}
		/* Refs page. */
		if(props.match.params.refs != null){
			component = <Refs lang={props.lang} history={props.history}/>;
		}
		return {
			component: component
		}
	};

	var mainComponent = getMainComponent();
	return(
		<>
			<Header {...props.match.params} logo={props.logo} lang={props.lang} hash={props.match.params.hash}/>
			<div className="content">
				{mainComponent.component}
			</div>
		</>
	);
};

export default LandscapeApp;