import React from 'react';
import $ from 'jquery';

class HorizontalBar extends React.Component{

	componentDidMount(){
		// To set aestetically pleasant rounded corners, we need to know the aspect ratio first.
		var $svg = $(this.refs.svg);		
		var rx = 1;
		var ry = 77;
		$svg.children().eq(0).attr("rx", rx);
		$svg.children().eq(0).attr("ry", ry);
		$svg.children().eq(1).attr("rx", rx);
		$svg.children().eq(1).attr("ry", ry);
	}

	render(){
		return(			
			<svg viewBox="0 0 100 100" preserveAspectRatio="none" ref="svg">
				<rect x="0" y="0" width={100} height={100}
					fill={this.props.inactiveColor} stroke={this.props.stroke}
					strokeWidth={this.props.strokeWidth}
				/>				
				<rect x="0" y="0" height={100} fill="#F5922A"
					stroke={this.props.stroke} strokeWidth={this.props.strokeWidth}>
						<animate attributeName="width" from="0" 
							to={this.props.value * 100} dur="1.3s" fill="freeze"
							calcMode="spline"
							keySplines="0,0,.58,1"
						/>
             	</rect>
			</svg>
		);
	}
}

export default HorizontalBar;