import React from 'react';
import strings from '../assets/strings.json';
import axios from 'axios';

class RisksList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: {}
        }
    }

    onLoadData() {
        axios.get(this.props.hash + "/plan/", {
            params: {
                lang: this.props.lang
            }
        })
            .then(result => {
                this.setState({
                    data: result.data.userData.userData[this.props.category][this.props.subcategory]
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount(){
        this.onLoadData();
    }

    render(){
        let lang = this.props.lang;
        if(lang == null){
            return <div/>;
        }

        let risks = strings[lang].web["no-recommendations"];
        let profile_descriptions;
        let data = [];
        if( this.state.data.hasOwnProperty('profile_description') ) {
            profile_descriptions = this.state.data.profile_description.map(function (profile_description) {
                return (
                    <li key={profile_description}>
                        <p dangerouslySetInnerHTML={{__html: profile_description}}/>
                    </li>
                )
            });
        }
        if( this.state.data.strings ) {
            risks = this.state.data.strings.map(function (risk) {
                return (
                    <li key={risk}>
                        <p dangerouslySetInnerHTML={{__html: risk}}/>
                    </li>
                )
            });
            if(profile_descriptions != null){
                data = [...profile_descriptions,...risks];
            }else{
                data = [...risks];
            }
        }
        return (
            <>
                <div className="outer-box">
                    <ul>
                        { data }
                    </ul>
                </div>

                {
                    this.state.data.extra != null ?
                        <div className="outer-box">
                            <ul>
                            {
                                this.state.data.extra.map( recommendation => (
                                        <li key={recommendation}>
                                            <p dangerouslySetInnerHTML={{__html: recommendation}}/>
                                        </li>
                                    )
                                )
                            }
                            </ul>
                        </div>
                    : null
                }



            </>

        );
    }
}
export default RisksList;