import React from "react";

const bodyIcon = ({ logo }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="30 10 50 90">
      <path
        fill={logo === "unilabs" ? "#525252" 
            : logo === "nimgenetics" ? "#525252" 
            : "#F5922A"}
        d="M38.25 59h-.15a.83.83 0 0 1-.68-1c.94-5.28 4.16-22.6 6.17-24.16a33.26 33.26 0 0 1 6.72-3.65c4.3-1.94 8.36-3.76 8.36-7.52a4.82 4.82 0 1 0-8 3.6.84.84 0 1 1-1.12 1.26 6.5 6.5 0 1 1 10.82-4.86c0 4.84-4.76 7-9.36 9.05a32.45 32.45 0 0 0-6.38 3.45c-1.07 1-3.67 12.58-5.55 23.12a.84.84 0 0 1-.83.71zM69.47 59a.85.85 0 0 1-.83-.7c-1.88-10.54-4.47-22.08-5.56-23.13a27.14 27.14 0 0 0-5.21-2.91.84.84 0 0 1 .7-1.53 28.36 28.36 0 0 1 5.56 3.12c2 1.56 5.23 18.88 6.17 24.16a.84.84 0 0 1-.68 1z M48.11 91.55a.84.84 0 0 1-.84-.84c0-6.94-.36-12.15-.67-16.75a82.27 82.27 0 0 1-.17-14.75C48.12 43.39 46 40.72 46 40.7a.84.84 0 1 1 1-1.34c.51.38 2.95 3.13 1.16 20a80.8 80.8 0 0 0 .16 14.45c.32 4.63.68 9.88.68 16.87a.83.83 0 0 1-.89.87zM59.6 91.55a.84.84 0 0 1-.84-.84c0-7 .36-12.24.68-16.86a80 80 0 0 0 .17-14.46c-1.8-16.9.65-19.65 1.15-20a.84.84 0 0 1 1.07 1.3c-.13.15-2.2 3-.55 18.55A81.31 81.31 0 0 1 61.12 74c-.32 4.6-.67 9.81-.67 16.75a.84.84 0 0 1-.85.8z"
      />
    </svg>
  );
};

export default bodyIcon;
