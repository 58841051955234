import React from 'react';
import cookiesData from '../assets/cookies.json';
import GoBack from "../components/BackButton";

var Cookies = function(props){
    var lang = props.lang;
    if(lang == null){
        return <div/>;
    }

    return(
        <>
            <GoBack history={ props.history } />
            <div dangerouslySetInnerHTML={{__html: cookiesData[lang]}}/>
        </>
    )
}

export default Cookies;