import React from 'react';
import Text from '../assets/strings.json';
import GoBack from "../components/BackButton";
import {LinkIcon} from "../components/LinkIcon";

var Refs = function(props){
    var lang = props.lang;
    if(lang == null){
        return <div/>;
    }

    return(
        <>
            <GoBack history={ props.history } />
            <div className={"ref-container"}>
                <h3>
                    {Text[lang].web.refs.title}
                </h3>

                <h4>
                    {Text[lang].web.refs["sub-one"]}
                </h4>
                <ul>
                    <li>
                        <div dangerouslySetInnerHTML={{__html: Text[lang].web.refs["li-one"]}}/> <a href={'http://www2.insa.pt/sites/INSA/Portugues/AreasCientificas/AlimentNutricao/AplicacoesOnline/TabelaAlimentos/PesquisaOnline/Paginas/DetalheAlimento.aspx?ID=IS878'}><LinkIcon size={'10px'}/></a>
                    </li>
                    <li>
                        <div dangerouslySetInnerHTML={{__html: Text[lang].web.refs["li-two"]}}/> <a href={'https://www.gov.uk/government/publications/composition-of-foods-integrated-dataset-cofid'}><LinkIcon size={'10px'}/></a>
                    </li>
                </ul>

                <h4>
                    {Text[lang].web.refs["sub-two"]}
                </h4>
                <ul>
                    <li>
                        <div dangerouslySetInnerHTML={{__html: Text[lang].web.refs["li-three"]}}/> <a href={'https://www.ipma.pt/resources.www/docs/publicacoes.site/pescado/site/indice.htm'}><LinkIcon size={'10px'}/></a>
                    </li>
                    <li>
                        <div dangerouslySetInnerHTML={{__html: Text[lang].web.refs["li-six"]}}/> <a href={'http://repositorio.insa.pt/bitstream/10400.18/3932/1/APN%202016_Poster_Sementes_FINAL.pdf'}><LinkIcon size={'10px'}/></a>
                    </li>
                    <li>
                        <span>{Text[lang].web.refs["list-five-title"]}</span>
                        <ul>
                            <li>
                                <div dangerouslySetInnerHTML={{__html: Text[lang].web.refs["sub-list-one"]}}/> <a href={'https://www.efsa.europa.eu/sites/default/files/assets/DRV_Summary_tables_jan_17.pdf'}><LinkIcon size={'10px'}/></a>
                            </li>
                            <li>
                                <div dangerouslySetInnerHTML={{__html: Text[lang].web.refs["sub-list-two"]}}/> <a href={'https://doi.org/10.1016/j.foodres.2011.02.012'}><LinkIcon size={'10px'}/></a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div dangerouslySetInnerHTML={{__html: Text[lang].web.refs["li-six"]}}/> <a href={'https://www.sphta.org.pt/pt/base8_detail/25/104'}><LinkIcon size={'10px'}/></a>
                    </li>
                </ul>
            </div>
        </>
    )
};

export default Refs;