import React from "react";
import MediaQuery from "react-responsive";
import queryStringParser from "query-string";
import LandscapeApp from "./landscape/App";
import PortraitApp from "./portrait/App";
import config from "./config/config.json";
import stringData from "./assets/strings.json";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const App = function (props) {
  let id = localStorage.getItem("id");
  if (id == null) {
    id = config.companies[0].value;
    localStorage.setItem("id", id);
  }

  /*
   * Language state is kept in localStorage.
   * When not specified, it defaults to the first language in the config object.
   */
  let lang = localStorage.getItem("lang");
  if (lang == null) {
    lang = config.languages[0];
    localStorage.setItem("lang", lang);
  }

  /*
   * Check if the optional GET parameter 'lang' was passed with a valid option.
   * If so, then set the language accordingly.
   */
  let queryParams = queryStringParser.parse(props.location.search);
  const compExists = config.companies.findIndex((obj) => {
    return obj.value === queryParams["id"];
  });
  if (Object.keys(queryParams).includes("id") && compExists !== -1) {
    id = queryParams["id"];
    localStorage.setItem("id", id);
  }

  if (
    Object.keys(queryParams).includes("lang") &&
    config.languages.includes(queryParams["lang"])
  ) {
    lang = queryParams["lang"];
    localStorage.setItem("lang", lang);
  }

  const [langState, setLang] = React.useState(lang);
  const [acceptedCookiesState, setAcceptedCookies] = React.useState(
    !!Cookies.get("CookieConsent")
  );
  const [idState, setId] = React.useState(id);

  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    if (process.env.REACT_APP_REACT_APP_ENV === "prod" && acceptedCookiesState) {
      trackPageView();
    }
  }, [acceptedCookiesState]);

	React.useEffect(() => {
    let manifestFile = document.getElementById("manifest")
    let favicon = document.getElementById("favicon")

    if (id === "1b7f647c") {
      document.title = "Invenimus";
      manifestFile.href = "/manifest_invenimus.json"
      favicon.href = "/invenimus_icons/favicon-32x32-seochecker-manifest-7510.png"
    } else {
      manifestFile.href = "/manifest.json"
      favicon.href = "/favicon.ico"
    }
  }, [id]);

  function goCookies() {
    window.location.href =
      config["front-end-server"]["url-prefix"] +
      props.match.params.hash +
      "/cookies";
  }

  const message = stringData[langState].web.cookies.policy;
  const linkText = stringData[langState].web.cookies["know-more"];
  const idx = config.companies.findIndex((obj) => {
    return obj.value === id;
  });
  const company = config.companies[idx].title;

  return (
    <div className={company}>
      <MediaQuery orientation="portrait">
        <PortraitApp {...props} lang={langState} logo={company} />
      </MediaQuery>
      <MediaQuery orientation="landscape">
        <LandscapeApp {...props} lang={langState} logo={company} />
      </MediaQuery>
      <CookieConsent
        buttonText="Okay"
        style={{ justifyContent: "flex-end", textAlign: "center" }}
        buttonStyle={{ backgroundColor: "#F5922A", borderRadius: "3px" }}
      >
        {message}
        <Link
          to={
            process.env.REACT_APP_FRONTEND_URL_PREFIX +
            props.match.params.hash +
            "/cookies"
          }
          className="cookie-know-more"
        >
          {linkText}
        </Link>
      </CookieConsent>
    </div>
  );
};

export default App;
