import React, { useState } from "react";
import Header from "../components/Header";
import BarList from "../components/BarList";
import FoodList from "../components/FoodList";
import SubcategoryItem from "../components/SubcategoryItem";
import Footer from "./Footer";
import Terms from "../page/Terms";
import Help from "../page/Help";
import Cookies from "../page/Cookies";
import IntoleranceItem from "../components/IntoleranceItem";
import Refs from "../page/Refs";
import strings from "../assets/strings.json";

import PWAPrompt from "react-ios-pwa-prompt";
import IOSPrompt from "./IOSPrompt";

let PortraitApp = function (props) {
  const [popup, setPopup] = useState("none");

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  // Detects if device is in standalone mode
  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  // Detects if device is is Safari
  function isInSafari() {
    let userAgentString = window.navigator.userAgent;

    // Detect Chrome
    let chromeAgent =
      userAgentString.indexOf("Chrome") > -1 ||
      userAgentString.indexOf("CriOS") > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf("Safari") > -1;

    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf("FxiOS") > -1;

    // Safari matches Chrome and firefox
    if ((chromeAgent && safariAgent) || (firefoxAgent && safariAgent))
      return false;
    return safariAgent;
  }

  const checkPropmtConditions = () => {
    if (!isInStandaloneMode() && isIos()) {
      if (isInSafari()) {
        return "PWAPrompt";
      } else {
        return "IOSPrompt";
      }
    } else {
      return "none";
    }
  };

  let whichPopup = localStorage.getItem("whichPopup");
  if (whichPopup === null) {
    whichPopup = checkPropmtConditions();
    localStorage.setItem("whichPopup", whichPopup);
  }

  React.useEffect(() => {
    if (whichPopup !== "none") {
      setPopup(whichPopup);
    }
  }, [whichPopup]);

  let getMainComponent = function () {
    let component;
    /* Subcategory page. May be a food list or a list of recommendations. */
    if (props.match.params.subcategory != null) {
      let category = props.match.params.category;
      if (
        category === "food" &&
        !(
          props.match.params.subcategory === "food_lactose" ||
          props.match.params.subcategory === "food_gluten"
        )
      ) {
        return {
          component: (
            <FoodList
              lang={props.lang}
              hash={props.match.params.hash}
              category={category}
              subcategory={props.match.params.subcategory}
            />
          ),
        };
      } else if (
        category === "food" &&
        (props.match.params.subcategory === "food_lactose" ||
          props.match.params.subcategory === "food_gluten")
      ) {
        return {
          component: (
            <IntoleranceItem
              lang={props.lang}
              hash={props.match.params.hash}
              category={category}
              subcategory={props.match.params.subcategory}
              history={props.history}
            />
          ),
        };
      } else {
        return {
          component: (
            <SubcategoryItem
              lang={props.lang}
              hash={props.match.params.hash}
              category={category}
              subcategory={props.match.params.subcategory}
              history={props.history}
            />
          ),
        };
      }
    }
    /* Category page. */
    if (props.match.params.category != null) {
      component = (
        <BarList
          lang={props.lang}
          hash={props.match.params.hash}
          category={props.match.params.category}
        />
      );
    }
    /* Terms&Conditions page. */
    if (props.match.params.terms != null) {
      component = <Terms lang={props.lang} history={props.history} />;
    }
    /* Help page. */
    if (props.match.params.help != null) {
      component = <Help lang={props.lang} history={props.history} />;
    }
    /* Cookies page. */
    if (props.match.params.cookies != null) {
      component = <Cookies lang={props.lang} history={props.history} />;
    }
    /* Refs page. */
    if (props.match.params.refs != null) {
      component = <Refs lang={props.lang} history={props.history} />;
    }
    return {
      component: component,
    };
  };

  let mainComponent = getMainComponent();

  let popupText = strings[props.lang].web["pwa-popup"];
  let popupDelay = 8000;

  return (
    <>
      <Header
        {...props.match.params}
        lang={props.lang}
        logo={props.logo}
        hash={props.match.params.hash}
      />

      <Footer {...props.match.params} logo={props.logo} />

      <div className="content">{mainComponent.component}</div>

      {popup === "PWAPrompt" ? (
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={1}
          delay={popupDelay}
          permanentlyHideOnDismiss={false}
          copyTitle={popupText["in-safari"].title}
          copyBody={popupText["in-safari"].body}
          copyShareButtonLabel={popupText["in-safari"].shareButtonLabel}
          copyAddHomeButtonLabel={popupText["in-safari"].addHomeButtonLabel}
          copyClosePrompt={popupText["in-safari"].close}
        />
      ) : popup === "IOSPrompt" ? (
        <IOSPrompt
          title={popupText["not-safari"].title}
          body={popupText["not-safari"].body}
          delay={popupDelay}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PortraitApp;
