import React from 'react';
import HorizontalBar from './HorizontalBar';
import config from '../config/config.json';
import strings from '../assets/strings.json';
import textData from '../assets/text-data.json';
import axios from 'axios';
import GoBack from "./BackButton";
import SomethingWentWrong from "./SomethingWentWrong";

class SubcategoryItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: {},
            hasError: false,
            error: null
        }
    }

    onLoadData() {
        axios.get(this.props.hash + "/plan/", {
            params: {
                lang: this.props.lang
            }
        })
            .then(result => {
                this.setState({
                    data: result.data.userData.userData[this.props.category][this.props.subcategory]
                });
            })
            .catch(err => {
                // console.log(err);
                this.setState({
                    hasError: true,
                    error: err
                });
            });
    }



    componentDidMount(){
        this.onLoadData();
    }

    render(){
        let lang = this.props.lang;
        let category = this.props.category;
        let subcategory = this.props.subcategory;
        let value = this.state.data.value || this.state.data.risk_score;

        if(lang == null || value == null){
            return <div/>;
        }


        let subcategoryLabel = textData[lang].subcategories[category][subcategory];
        let recommendations = <li><p>{strings[this.props.lang].web["no-recommendations"]}</p></li>;
        let profile_descriptions;
        let data = [];
        if( this.state.data.hasOwnProperty('profile_description') ) {
            profile_descriptions = this.state.data.profile_description.map(function (profile_description) {
                return (
                    <li key={profile_description}>
                        <p dangerouslySetInnerHTML={{__html: profile_description}}/>
                    </li>
                )
            });
        }
        if( this.state.data.strings ) {
            recommendations = this.state.data.strings.map(function (recommendation) {
                return (
                    <li key={recommendation}>
                        <p dangerouslySetInnerHTML={{__html: recommendation}}/>
                    </li>
                )
            });
            if(profile_descriptions != null){
                data = [...profile_descriptions,...recommendations];
            }else{
                data = [...recommendations];
            }
        }
        if( !this.state.hasError) {
            return (
                <div>
                    <GoBack history={ this.props.history } />
                    <div className="horizontal-bar-container">
                        <img src={require("../assets/img/" + subcategory + ".png").default} alt="" />
                        <h5>{subcategoryLabel}</h5>
                        <div className="horizontal-bar">
                            <HorizontalBar
                                activeColor={config.colors[category + "-color"]}
                                inactiveColor={config.colors[category + "-color-faded"]}
                                value={value}
                                stroke="black"
                                strokeWidth={0}
                            />
                        </div>
                    </div>
                    <div className="scale-container">
                        <div className="horizontal-bar-percentage">
                            <div className="percentage-container">
                                <div className="column-group">
                                    <div className="all-25">
                                        <div className="percentage text-left">
                                            <small>0%</small>
                                        </div>
                                    </div>
                                    <div className="all-15">
                                        <div className="percentage text-left">
                                            <small>25%</small>
                                        </div>
                                    </div>
                                    <div className="all-20">
                                        <div className="percentage text-center">
                                            <small>50%</small>
                                        </div>
                                    </div>
                                    <div className="all-15">
                                        <div className="percentage text-right">
                                            <small>75%</small>
                                        </div>
                                    </div>
                                    <div className="all-25">
                                        <div className="percentage text-right">
                                            <small>100%</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="outer-box">
                        <ul>
                            { data }
                        </ul>
                    </div>

                    {
                        this.state.data.extra != null ?
                            <div className="outer-box">
                                <ul>
                                    {
                                        this.state.data.extra.map( recommendation => (
                                                <li key={recommendation}>
                                                    <p dangerouslySetInnerHTML={{__html: recommendation}}/>
                                                </li>
                                            )
                                        )
                                    }
                                </ul>
                            </div>
                            : null
                    }


                </div>
            );
        }else{
            return <SomethingWentWrong error={ this.state.error } lang={lang}/>
        }

    }
}
export default SubcategoryItem;