import React from "react";

const FoodIcon = ({logo}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="30 20 50 70">
      <path
        fill={logo === "unilabs" ? "#525252" 
            : logo === "nimgenetics" ? "#525252" 
            : "#F5922A"}
        d="M70.1 84.31a.83.83 0 0 1-.84-.84V27.1c-3.19 5.18-10.09 17.71-8.08 26.32a9.92 9.92 0 0 0 5.1 6.68.84.84 0 1 1-.81 1.47 11.54 11.54 0 0 1-5.93-7.77c-2.75-11.76 9.35-29.3 9.87-30a.86.86 0 0 1 .94-.32.85.85 0 0 1 .59.8v59.19a.84.84 0 0 1-.84.84zM43.8 84.31a.84.84 0 0 1-.84-.84V49.72a.84.84 0 1 1 1.68 0v33.75a.83.83 0 0 1-.84.84z M43.8 50.56c-3.87 0-7-2.8-7-6.23V26.77a.84.84 0 1 1 1.68 0v17.56c0 2.51 2.4 4.55 5.34 4.55s5.34-2 5.34-4.55V26.77a.85.85 0 0 1 1.69 0v17.56c-.02 3.43-3.17 6.23-7.05 6.23z M43.8 46.78a.85.85 0 0 1-.84-.84V26.77a.84.84 0 0 1 1.68 0v19.17a.84.84 0 0 1-.84.84z"
      />
    </svg>
  );
};

export default FoodIcon;
