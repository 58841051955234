import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import config from '../config/config.json';
import strings from '../assets/strings.json';
import { Link } from "react-router-dom";

// eslint-disable-next-line no-restricted-globals

class PortraitHeader extends React.Component{
	constructor(props){
		super(props);
		this.switchMenu = this.switchMenu.bind(this);
		this.setLanguage = this.setLanguage.bind(this);		
		this.state = {
			menuIsOpen: false
		}
	}

	componentDidMount(){}


	switchMenu(){
		this.setState({
			menuIsOpen: !this.state.menuIsOpen
		});

	}


	setLanguage(lang){
    	localStorage.setItem("lang", lang); // Set language state.

	    // Strip URL of any get parameters, otherwise the language won't be changed. Then reload page.
		var expr = new RegExp("\\?lang=.+");
		
    	if(window.location.href.match(expr) != null){ // If URL ends with lang=...
      		// Remove language GET parameter, otherwise the language won't be changed.
      		var newHref = window.location.href.replace(expr, "");
      		// Load new URL.
      		window.location.href = newHref;
    	}else{
      		// Simply reload page with new language code.
      	window.location.reload();
    	}    
  	}
	
	render(){
		var component = this;
		const path = process.env.REACT_APP_FRONTEND_URL_PREFIX;
		var imgPrefix = "../assets/img/";
		let company = this.props.logo;
		return(
			<div className="header-container">
				<div className="header">
					<div className="header-left">
						<Link to={path + this.props.hash + "/body"}>
							<img src={require("../assets/img/" + company + ".png").default} alt="logo" className={company}/>
						</Link>
					</div>
					<div className="header-right">
						<img src={require("../assets/img/menu.svg").default} onClick={this.switchMenu} alt="" />
					</div>
				</div>
				<Menu right noOverlay width={'100%'}
					  isOpen={this.state.menuIsOpen}
					  onStateChange={function(state){component.setState({menuIsOpen: state.isOpen})}}
					  customCrossIcon={ <img src={require("../assets/img/close.svg").default} alt="" /> }
					  customBurgerIcon={ false }>
					<ul>
						<li>
							<ul className="bm-lang-list">
								{config.languages.map(function(lang, i){
									var text = strings[lang].web["language-label"];
									return(
										<li key={i}>
											<a onClick={function(){component.setLanguage(lang)}}>
												<img className="flag" src={require("../assets/img/flag_" + text + ".png").default} alt=""/>
											</a>
										</li>
									);
								})}
							</ul>
						</li>
						<li>
							<Link to={ path + this.props.hash + "/cookies" } onClick={this.switchMenu}>
								{strings[this.props.lang].web.menu["cookies"]}
							</Link>
						</li>
						<li>
							<Link to={ path + this.props.hash + "/terms" } onClick={this.switchMenu}>
								{strings[this.props.lang].web.menu["terms-and-conditions"]}
							</Link>
						</li>
						<li>
							<Link to={ path + this.props.hash + "/help" } onClick={this.switchMenu}>
								{strings[this.props.lang].web.menu["help"]}
							</Link>
						</li>
						<li>
							<Link to={ path + this.props.hash + "/refs" } onClick={this.switchMenu}>
								{strings[this.props.lang].web.menu["refs"]}
							</Link>
						</li>

						{
							company !== "hg" ?
								(
									<li className="powered-by">
										<p><small>Powered by:</small></p>
										<img className="header-menu-image" src={require("../assets/img/heartcolor.png").default} alt="company logo"/>
									</li>
								)
								: null
						}

						<li className="portugal-2020">
							<img className="header-menu-image" src={require("../assets/img/2020.png").default} alt="" />
						</li>
					</ul>
				</Menu>
			</div>
		);
	}
}

export default PortraitHeader;
