import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import NoMatch from "./page/NoMatch";
import config from "./config/config.json";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

axios.defaults.baseURL = 
  process.env.REACT_APP_BACKEND_HOST +
  process.env.REACT_APP_BACKEND_URL_PREFIX;

var instance = null;

if (process.env.REACT_APP_REACT_APP_ENV === "prod") {
  instance = createInstance({
    urlBase: `${process.env.REACT_APP_FRONTEND_HOST}/webapp`,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    trackerUrl: `${process.env.MATOMO_HOST}/matomo.php`,
    srcUrl: `${process.env.MATOMO_HOST}/matomo.js`,
    linkTracking: true,
  });
}

const customHistory = createBrowserHistory();

// Front-end URL prefix. All front-end webapp URLs start with this prefix, e.g. /webapp/.
let feUrlPrefix = process.env.REACT_APP_FRONTEND_URL_PREFIX;
let notSpecifiedPath = feUrlPrefix + ":hash/";
let planPath = feUrlPrefix + ":hash/plan";
let bodyPath = feUrlPrefix + ":hash/body";

ReactDOM.render(
  <MatomoProvider value={instance}>
    <Router history={customHistory}>
      <Switch>
        {/* Routing for main category pages, e.g. /webapp/<hash>/body.*/}
        {config.categories.map(function (cat, i) {
          let path = feUrlPrefix + ":hash/:category(" + cat + ")";
          return <Route exact key={i} path={path} component={App} />;
        })}
        ;
        <Redirect from={planPath} to={bodyPath} />
        {/* Routing for subcategory pages, e.g. /webapp/<hash>/body/bmi */}
        {config.categories.map(function (cat, i) {
          return config.subcategories[cat].map(function (subcat, j) {
            let path =
              feUrlPrefix +
              ":hash/:category(" +
              cat +
              ")" +
              "/:subcategory(" +
              subcat +
              ")";
            return (
              <Route exact key={i + "-" + j} path={path} component={App} />
            );
          });
        })}
        {/* Routing for cookies page */}
        <Route
          exact
          path={feUrlPrefix + ":hash/:cookies(cookies)"}
          component={App}
        />
        {/* Routing for terms page.*/}
        <Route
          exact
          path={feUrlPrefix + ":hash/:terms(terms)"}
          component={App}
        />
        {/* Routing for help page.*/}
        <Route exact path={feUrlPrefix + ":hash/:help(help)"} component={App} />
        {/* Routing for help page.*/}
        <Route exact path={feUrlPrefix + ":hash/:refs(refs)"} component={App} />
        {/* Redirect whenever the user forgets to add a specific category */}
        <Redirect from={notSpecifiedPath} to={bodyPath} />
        {/* 404 component that catches all invalid paths. */}
        <Route component={NoMatch} />
      </Switch>
    </Router>
  </MatomoProvider>,
  document.getElementById("root")
);

serviceWorker.register();
