import React from "react";
import config from "../config/config.json";
import { NavLink } from "react-router-dom";

import BodyIcon from "../assets/img/BodyIcon.js";
import BehaviorIcon from "../assets/img/BehaviorIcon.js";
import FoodIcon from "../assets/img/FoodIcon.js";

var PortraitFooter = function (props) {
  // eslint-disable-next-line no-restricted-globals

  var href = process.env.REACT_APP_FRONTEND_URL_PREFIX + props.hash;

  return (
    <ul className="nav">
      <li className="nav-item">
        <NavLink to={href + "/body"} activeClassName="active">
          <div className="image-wrapper">
            {window.location.href.indexOf("/body") > -1 ? (
              <BodyIcon logo={props.logo} />
            ) : (
              <img
                src={require("../assets/img/body-footer-icon.svg").default}
                alt=""
              />
            )}
          </div>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={href + "/food"} activeClassName="active">
          <div className="image-wrapper">
            {window.location.href.indexOf("/food") > -1 ? (
              <FoodIcon logo={props.logo} />
            ) : (
              <img
                src={require("../assets/img/food-footer-icon.svg").default}
                alt=""
              />
            )}
          </div>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={href + "/behavior"} activeClassName="active">
          <div className="image-wrapper">
            {window.location.href.indexOf("/behavior") > -1 ? (
              <BehaviorIcon logo={props.logo} />
            ) : (
              <img
                src={require("../assets/img/behavior-footer-icon.svg").default}
                alt=""
              />
            )}
          </div>
        </NavLink>
      </li>
    </ul>
  );
};

export default PortraitFooter;
