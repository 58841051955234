import React from 'react';
import HorizontalBar from './HorizontalBar';
import config from '../config/config.json';
import data from '../assets/text-data.json';
import {withRouter} from 'react-router-dom'
import GoBack from "./BackButton";
import RisksList from "./RisksList";
import strings from "../assets/strings";

var FoodList = function(props){
    const lang = props.lang;
    const category = props.category;
    const subcategory = props.subcategory;
    let recommendationLabel = strings[lang].web["recommended-food"];
    let observationLabel = strings[lang].web["observations"];
    let subcategoryLabel = data[lang].subcategories[category][subcategory];
    const query = new URLSearchParams(props.location.search);
    const horizontalBarValue = query.get('value');
    let foodlist = data[lang].foodLists.map(function (e, i) {
		if(e.label === subcategoryLabel && e.label !== "food_restriction") {
            return data[lang].foodLists[i].foodList.map(function (e, i) {
                let bullets = e.bullets.map(function (e) {
                    return (
                        <li key={e}>
                            {e}
                        </li>);
                });
                if( e.name[0] !== "CAPTION" ) {
                    return (
                        <div key={i}>
                            {
                                (subcategory === "food_caffeine" || subcategory === "food_salt" || subcategory === "food_fat" )
                                    ? <h5>{observationLabel}</h5>
                                    : <h5 className="food-subtitle">{e.label.join('')}</h5>
                            }
                            <ul key={i}>{bullets}</ul>
                        </div>
                    )
                }else{
                    return (
                        <div key={i}>
                            <ul className="food-caption">{bullets}</ul>
                            <ul className="ref-sentence">
                                <li>{strings[lang].web["ref-sentence"]}</li>
                            </ul>
                        </div>
                    )
                }
            });
        }
        return '';
    });


    return(
		<>
            <GoBack history={props.history} lang={lang}/>
			<div className="horizontal-bar-container">
				<img src={require("../assets/img/" + subcategory + ".png").default} alt="" />
                <h5>{subcategoryLabel}</h5>
                <div className="horizontal-bar">
                    <HorizontalBar
                        activeColor={config.colors["body-color"]}
                        inactiveColor={config.colors["body-color-faded"]}
                        value={horizontalBarValue}
                        stroke="black"
                        strokeWidth={0}
                    />
                </div>
			</div>
            <div className="scale-container">
                <div className="horizontal-bar-percentage">
                    <div className="percentage-container">
                        <div className="column-group">
                            <div className="all-25">
                                <div className="percentage text-left">
                                    <small>0%</small>
                                </div>
                            </div>
                            <div className="all-15">
                                <div className="percentage text-left">
                                    <small>25%</small>
                                </div>
                            </div>
                            <div className="all-20">
                                <div className="percentage text-center">
                                    <small>50%</small>
                                </div>
                            </div>
                            <div className="all-15">
                                <div className="percentage text-right">
                                    <small>75%</small>
                                </div>
                            </div>
                            <div className="all-25">
                                <div className="percentage text-right">
                                    <small>100%</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RisksList
            lang={props.lang}
            hash={props.match.params.hash}
            category={category}
            subcategory={subcategory} />

            {
                subcategory === "food_restriction" || subcategory === "food_caffeine" || subcategory === "food_salt" || subcategory === "food_fat"
                ? null
                : <h5>{recommendationLabel}</h5>
            }

			{foodlist}
		</>

	);
}
export default withRouter(FoodList);
