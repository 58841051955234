import React from 'react';
import strings from '../assets/strings.json';
import GoBack from "../components/BackButton";

var Help = function(props){
	const lang = props.lang;
	if(lang == null){
		return <div/>;
	}

	return (
		<>
			<GoBack history={ props.history } />
			<div className="help-container">
				<h3>{strings[lang].help.title}</h3>
				<p>{strings[lang].help["sub-title"]}</p>

				<h4>{strings[lang].help["how-to-navigate-title"]}</h4>
				<ul>
					<li dangerouslySetInnerHTML={{__html: strings[lang].help["how-to-navigate-step-one"]}} />
					<li>{strings[lang].help["how-to-navigate-step-two"]}</li>
					<li>{strings[lang].help["how-to-navigate-step-three"]}</li>
				</ul>

				<p>{strings[lang].help["watch-video"]}</p>
				<div className="iframe-container">
					<iframe width="560" height="315" src="https://www.youtube.com/embed/ks3o-ag4S80" frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen title="a"/>
				</div>

				<h4>{strings[lang].help["how-to-make-shortcut-title-android"]}</h4>
				<ul>
					<li>{strings[lang].help["how-to-make-shortcut-step-one"]}</li>
					<li>{strings[lang].help["how-to-make-shortcut-step-two"]}</li>
					<li>{strings[lang].help["how-to-make-shortcut-step-three"]}</li>
				</ul>
				<p>{strings[lang].help["watch-video"]}</p>
				<div className="iframe-container">
					<iframe width="560" height="315" src="https://www.youtube.com/embed/DxAK7GpcD30" frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen title="b" />
				</div>

				<h4>{strings[lang].help["how-to-make-shortcut-title-ios"]}</h4>
				<ul>
					<li>{strings[lang].help["how-to-make-shortcut-ios-step-one"]}</li>
					<li>{strings[lang].help["how-to-make-shortcut-ios-step-two"]}</li>
					<li>{strings[lang].help["how-to-make-shortcut-ios-step-three"]}</li>
				</ul>
				<p>{strings[lang].help["watch-video"]}</p>
				<div className="iframe-container">
					<iframe width="560" height="315" src="https://www.youtube.com/embed/MBx4jODSUgg" frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen title="c"/>
				</div>
			</div>
		</>
	)
};

export default Help;