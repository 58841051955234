import React from 'react';

var NoMatch = function(){
	return(
        <div className="error-wrapper">
            <div className="text-wrapper">
                <h1>Oops!</h1>
                <h2>We can't seem to find the page you are looking for</h2>
                <p>Error code: 404</p>
            </div>
            <div className="img-wrapper">
                <img src="/img/404-adna.png" alt="DNA" />
            </div>
        </div>
	);
};
export default NoMatch;